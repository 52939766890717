import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/co-to-jest-crm-tlo.png";
import Lightbox from "../components/lightBox";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";
import { Link } from "gatsby";


export const query = graphql`
  {
    zdjecie1: file(
      relativePath: { eq: "crm-proces-sprzedazy-elastyczny-crm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(
      relativePath: { eq: "co-to-jest-crm-baza-kontrahentow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
            karta: file(relativePath: { eq: "karta-kontrahenta-questy.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
            raporty: file(relativePath: { eq: "raporty-sprzedaz-questy.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="CRM. Co to jest CRM? Wywiad z ekspertem"
      keywords={["co to jest crm"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Co to jest CRM? Jakie są najważniejsze korzyści?"
      metaTitle="CRM. Co to jest CRM? Wywiad z ekspertem"
      metaDescription="Co to jest CRM? - Praktyka, przykłady, definicja, korzyści, cechy. • Wszystkie najważniejsze informacje w jednym miejscu • [Video]"
    >
      
      <p style={{
          paddingTop: 30,
        }}>CRM jako oprogramowanie stało się w ostatnich latach popularnym i szeroko chwalonym rozwiązaniem, które ma odpowiadać za 
        usprawnienie funkcjonowania i zwiększenie efektywności zarządzania firmą. Najlepsze oprogramowania CRM swoje zastosowanie 
        znajdą zarówno w małych i średnich przedsiębiorstwach, jak i w dużo większych firmach. Może się jednak zdarzyć, 
        że w danej firmie tego typu rozwiązanie CRM nie zostanie poprawnie wdrożone, co niekiedy ma gorszy wpływ niż całkowity brak 
        systemu CRM. Z jakimi konsekwencjami może się to wiązać i, przede wszystkim, jak tego uniknąć?
        </p>
      <p>
      W pierwszym odcinku z serii wywiadów przedstawiających punkt widzenia praktyków na temat sprzedaży, obsługi klienta i nowoczesnej technologii, 
      Joanna Cieluch rozmawia z Łukaszem Tadyszakiem – założycielem i CEO Questy. To ekspert i praktyk z ponad 20-letnim doświadczeniem 
      w zakresie projektowania i wdrażania systemów wspierających zarządzanie obsługą klientów i sprzedażą, a także absolwent MBA.
      </p>
      <p><strong>Serdecznie zachęcamy do zapoznania się z poniższym materiałem wideo lub przeczytania transkrypcji wywiadu. :)</strong></p>
      <p style={{
          paddingBottom: 20,
        }}><strong>1. Wideo: Wywiad z ekspetem </strong>– strategia CRM i skuteczne wdrożenie systemu CRM w firmie<br />
      <strong>2. Wideo: Co to jest CRM </strong>– Definicja CRM w pigułce: filozofia i system CRM online</p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="uqoYcMSRL9A"
          title="Co to jest CRM?: Klient jest NAJWAŻNIEJSZY! System CRM w zarządzaniu"
        />
      </div>

      <p style={{
          paddingTop: 20,
        }}><strong> 
        Ok, zacznijmy od początku. Czym właściwie jest CRM?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
       Jeśli poszperamy w Internecie, to pewnie znajdziemy definicje, które będą odnosiły się do&nbsp;oprogramowania czy technologii, która ma 
       wspierać organizację w określonych obszarach – aczkolwiek w mojej ocenie jest to tylko część prawdy. Myślę, że poza samym 
       oprogramowaniem, CRM jest też pewnym sposobem radzenia sobie organizacji z&nbsp;problemami czy procesami związanymi z&nbsp;obsługą klienta.

        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        Skoro już jesteśmy przy technologii – jak dobrze wiesz, na rynku jest już kilka rodzajów systemów CRM. Czym one się właściwie od&nbsp;siebie różnią?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
       Najprościej będzie wyjaśnić to używając analogii. Porównując to do motoryzacji – na rynku mamy samochody, które będą 
       służyły jako tak zwane „woły robocze dla handlowców”, czyli typowe Skody Octavie i tak dalej, możemy myśleć też o&nbsp;ciężarówkach, 
       które służą do przewozu towarów, możemy też myśleć o samochodach sportowych. Z platformą CRM jest podobnie – będą one miały różne cechy i różne zastosowania.
        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        Czyli, jak dobrze rozumiem, nie ma uniwersalnego rozwiązania, nie każdy CRM sprawdzi się w każdym przedsiębiorstwie?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
       Zgadza się.
        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        Ok. Często słyszy się, że CRM w rzeczywistości jest narzędziem dla handlowca – szef kupuje, handlowiec od teraz 
        sprzedaje więcej, efektywniej… Czy rzeczywiście tak jest? Czy CRM to jest tylko narzędzie dla handlowców? 
        Dla kogo właściwie kupuje się CRM-a?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      CRM na pewno jest narzędziem dla ludzi, których zadaniem jest pozyskiwać klientów, pozyskiwać kontrakty, a&nbsp;więc tak 
      zwanych handlowców. Myślę jednak, że to tylko fragment tego, co możemy osiągnąć dzięki takiemu rozwiązaniu. Drugą rolą, 
      która na pewno z CRM-a skorzysta będzie przełożony naszego handlowca, jego szef, albo na przykład dział obsługi klienta, 
      który będzie obsługiwał po prostu różnego rodzaju zamówienia, zapytania – wspierał procesy związane z reklamacjami. 
      Także tych ról w organizacji może być więcej.
        </p>
                <p style={{
          paddingTop: 15,
        }}><strong> 
        Często spotykam się z pytaniem od handlowców „Ok, ale co ja z tego będę miał? Co mi to da?”. 
        Wspomniałeś już o kilku rolach w organizacji – jakbyś mógł powiedzieć, co konkretnie te role zyskają na wdrożeniu CRM-a?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Jeśli CRM będzie wdrożony w prawidłowy sposób, to na pewno poprawimy ich efektywność, czyli nasi pracownicy będą w stanie 
      osiągać rezultaty w łatwiejszy sposób – oszczędzą swój czas – oraz powinni osiągać wyższa wyniki, a więc dla firmy zarobią 
      więcej. Ostatecznie powinno się to więc przełożyć po prostu na ich zarobki i komfort pracy.
        </p>
                        <p style={{
          paddingTop: 15,
        }}><strong> 
        Nawiązując do tematu odcinka – mówimy o&nbsp;tym, czym jest CRM i czy CRM to faktycznie sama instalacja systemu. 
        Więc jak to jest, czy wystarczy zainstalować CRM-a, aby organizacja osiągnęła sukces – to jest celem wdrożenia?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Użyję analogii – to jest tak samo, jak gdybyśmy zakładali, że dając pracownikom karty Multisport sprawimy, że&nbsp;będą 
      wysportowani i zrelaksowani. Nie jest to, niestety, takie oczywiste i proste. Oczywiście trzeba zainstalować 
      system, technicznie sprawić, żeby po prostu działał, ale poza tym trzeba też wprowadzić pewną zmianę w kulturze 
      pracy w organizacji i dopiero wtedy osiągamy odpowiedni efekt.
        </p>
                                <p style={{
          paddingTop: 15,
        }}><strong> 
        To co właściwie ma zrobić szef firmy, który właśnie kupił CRM-a i go wdraża?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Przede wszystkim zdefiniować swoje oczekiwania, albo, od innej strony, zdefiniować problemy, z którymi na co 
      dzień się boryka, które CRM powinien pozwolić rozwiązać. Następnie, jeżeli już wiemy, co chcemy zrobić, powinniśmy wdrożyć odpowiednie procesy.
        </p>
        <p>Jeżeli problemem w organizacji jest, na przykład, utrata wiedzy na temat klienta 
        związana np. z&nbsp;rotacją pracowników, to rozwiązaniem będzie baza wiedzy o klientach. 
        No i taka baza wiedzy musi być po prostu prowadzona na bieżąco. Nie jest to jednorazowe 
        działanie, trzeba nauczyć pracowników pracować z&nbsp;systemem – i to jest jeden z przykładów.</p>
             <p style={{
          paddingTop: 15,
        }}><strong> 
        Myślę, że nasz materiał dotrze zarówno do sektora MŚP, jak i do większych organizacji. 
        Zacznijmy może od małej skali. Co musi zrobić szef małej firmy, mała firma, aby to wdrożenie 
        zakończyło się sukcesem? Aby firma osiągnęła zakładane cele i rozwiązała problemy, o&nbsp;których wspomniałeś przed chwilą?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
        W małej firmie jest to stosunkowo prosta sprawa. Wydaje mi się, że największym zagrożeniem jest pewien chaos, który czasami 
        szefowie w małych firmach potrafią wprowadzać – wszystko realizują spontanicznie, żaden proces nie jest poukładany.
        </p>
        <p>Taki szef powinien jasno zdefiniować oczekiwania, jak na przykład ma wyglądać proces obsługi szansy 
        sprzedaży i kto za to odpowiada. Jeżeli po zdefiniowaniu tego będzie konsekwentnie wymagał tego sposobu działania 
        od swoich pracowników (ewentualnie dostosowywał się do zmieniających się realiów, czyli jak coś nie działa, 
        to trzeba to po prostu poprawić), to myślę, że to jest wszystko, co trzeba zrobić.
        </p>
         <p style={{
          paddingTop: 15,
        }}><strong> 
        Czyli konsekwencja, mierzenie, wprowadzanie usprawnień… i tak w kółko.
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Tak jest.
        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
        Ok, a jak by to wyglądało w przypadku dużej organizacji? Są jakieś różnice?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Zdecydowanie tak. Po pierwsze, nie rozmawiamy wtedy o tych samych rozwiązaniach. 
      One są z&nbsp;reguły bardziej złożone, tych procesów może być więcej, mogą w nich uczestniczyć 
      kolejni ludzie, kolejne role – więcej osób zazwyczaj pracuje z&nbsp;klientami.
        </p>
        <p>Wdrożenie oprogramowania CRM powinno rozpocząć się od gruntownej analizy – trzeba ustalić w ogóle jak 
        firma działa i co chcemy usprawniać, co chcemy zmieniać. Oczywiście, nie chodzi o to tylko, żeby wdrożyć 
        system i&nbsp;pracować w ten sam sposób – takie wdrożenie moim zdaniem jest porażką. Więc chcemy coś 
        zmienić, ale musimy po pierwsze wiedzieć z&nbsp;czego na co.
        </p>
        <p>Po drugie, należy zaplanować tą zmianę. Czyli opisać sobie jak te kroki mają konkretnie 
        wyglądać, kto będzie za nie odpowiadał, odpowiednio te role również przygotować do zarządzania 
        zmianą – no i zarządzić zmianą tak, żeby organizacja przyjęła dane rozwiązanie CRM od A do Z.</p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
       A kto powinien być (i za co) odpowiedzialny? Czy większość tych aktywności, które wymieniłeś, jest po stronie 
       dostawcy oprogramowania, czy raczej chodzi tutaj o&nbsp;współpracę pomiędzy dostawcą a&nbsp;klientem? Może jest jeszcze jakaś inna opcja?

        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      No cóż, ja myślę, że to jest – użyję znowu analogii – tak, jak w tańcu, a więc musi być ktoś, kto w tym 
      tańcu prowadzi, a zakładam, że tym kimś jest po prostu dostawca, ponieważ ma wiedzę, know-how i powinien 
      klienta poprowadzić przez ten proces.
        </p>
        <p>Z drugiej strony, użytkownik systemu CRM powinien dać się poprowadzić, czyli po prostu współpracować, 
        być zaangażowanym i&nbsp;wykonywać zalecenia. Jeżeli ułożymy sobie pewien plan i harmonogram działań, to ten 
        harmonogram powinien być realizowany przez każdą ze stron, zgodnie z planem. Ewentualnie, jeżeli 
        plan wymaga korekty, to powinno być to&nbsp;uzgodnione, a ta zmiana odpowiednio zarządzona.
        </p>
        <p style={{
          paddingTop: 15,
        }}><strong> 
       Żeby tak trochę podsumować, to może zadam najważniejsze pytanie – po czym firma pozna, że udało się wdrożyć 
       system CRM z sukcesem? Co realnie osiągnie?
        </strong></p>
        <p style={{
          paddingTop: 5,
        }}> 
      Część osób myśli, że wtedy mamy sukces, kiedy ludzie wklepują dużą ilość informacji do systemu – myślę, że to zupełnie nie o to chodzi. 
      To, czy udało się skutecznie wdrożyć system CRM poznałbym to po tym, że:
        <ul>
        <li>problemy, które pierwotnie mieliśmy po prostu zniknęły, </li>
        <li>każdy wie, za co odpowiada,  </li>
        <li>dany proces, na przykład proces obsługi klienta przebiega zgodnie z założeniami.</li></ul></p>
        <p>Jeżeli założyliśmy, że klient ma otrzymać odpowiedź w ciągu godziny od złożenia zapytania, to ta odpowiedź 
        faktycznie w ciągu godziny jest. A jeśli nie jest, to management bez problemu jest w stanie ustalić dlaczego – 
        czy kwestia jest po prostu nagłego peaku związanego z dużą ilością zapytań, czy absencji pracowników… 
        W&nbsp;każdym razie, mamy też wiedzę, co się dzieje.
        </p>
        <p>Prawidłowo działający, odpowiedni system CRM ma więc dać managementowi 
        bardzo dużo informacji, które pozwalają na lepsze zarządzanie organizacją.</p>

        <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Sprawdź korzyści z wdrożenia systemu CRM w Twojej firmie</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>
      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;z&nbsp;sukcesem wdrożyć system CRM
            </Link>
          </div>
          </div>
    </section>

      <h2 style={{
          paddingTop: 40,
        }}><strong>Co to CRM i&nbsp;do czego służy? Filozofia i system CRM online</strong></h2>
      <p style={{
          paddingTop: 30,
          paddingBottom:20,
        }}>
       Sukces w&nbsp;zarządzaniu firmą jest ściśle uzależniony od jej grupy odbiorców – potencjalnych klientów, 
       którzy generują przedsiębiorstwu zyski. Najlepiej, jeżeli relacje te nie są jednorazowe – o&nbsp;wiele 
       cenniejsza jest bowiem nie pojedyncza sprzedaż, ale utrzymanie klienta i spowodowanie, by stał się 
       naszym stałym odbiorcą i&nbsp;cyklicznie korzystał z&nbsp;naszych usług. Właśnie to jest jednym z&nbsp;najważniejszych 
       powodów, dla których korzysta się z&nbsp;systemów CRM. Do prowadzenia biznesu w&nbsp;ten sposób powstało bowiem 
       wiele różnych narzędzi, ale najważniejszym z nich jest CRM dla firm, czyli system zarządzania relacjami z&nbsp;klientami.
      </p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="xW9lbrQOO6s"
          title="Co to jest CRM?: Klient jest NAJWAŻNIEJSZY! System CRM w zarządzaniu"
        />
      </div>
      
      <p style={{
          paddingTop: 30,
        }}>
        CRM - co to jest i&nbsp;jaka jest właściwie definicja CRM? Wyrażenie “CRM” to skrót z&nbsp;angielskiego “Customer Relationship Management”. 
        Już samo przetłumaczenie tego pojęcia na język polski jest niezłą definicją tego, czym jest CRM i&nbsp;do czego służy – “zarządzanie 
        relacjami z&nbsp;klientem”. Rozwiązania CRM to nie tylko proste narzędzia, ale również strategia biznesowa. Filozofia CRM skupia 
        się na zarządzaniu potencjalnym kliencie danej firmy, uznając, jest on najważniejszy. To właśnie on i jego 
        zadowolenie z&nbsp;naszych produktów oraz usług są dla firmy najwyższą wartością.
      </p>
      <p style={{
          paddingBottom: 15,
        }}>
        Przyjrzyjmy się nieco bliżej zadaniom systemu CRM, aby zobaczyć z&nbsp;jakich modułów jest on zbudowany, 
        co składa się na najważniejsze zalety systemu CRM oraz jak w&nbsp;praktyce może wyglądać skuteczna obsługa klienta dzięki systemowi CRM.

      </p>
      <h2 style={{
          paddingBottom: 10,
        }}>Podstawa CRM – baza danych klientów</h2>
      <p>
        Jedno z&nbsp;najważniejszych usprawnień uzyskiwanych dzięki systemowi CRM w&nbsp;sprzedaży polega na tym, że wszystkich swoich 
        kontrahentów wraz z&nbsp;najważniejszymi o nich informacjami mamy w jednym miejscu. <Link to="/aplikacja-crm-do-pracy-w-terenie"><strong>Mobilny CRM </strong></Link> sprawia, że 
        wszystkie kluczowe dane dostępne są online (w&nbsp;chmurze), aby z&nbsp;nich skorzystać wystarczy więc dowolne urządzenie 
        z&nbsp;dostępem do Internetu.
      </p>
      <p>
        Firmy bez narzędzia CRM często borykają się z problemem łatwego dostępu do kluczowych informacji, takich chociażby jak to, 
        czy ktoś już z klientem rozmawiał, a&nbsp;jeśli tak – to o&nbsp;czym konkretnie, co zostało ustalone i&nbsp;tak dalej. Często słyszy się 
        nawet o&nbsp;przypadkach, w których dwóch handlowców z&nbsp;jednego zespołu sprzedaży kontaktuje się z&nbsp;klientem niezależnie od siebie, 
        ale za to w&nbsp;tej samej sprawie. To jedne z&nbsp;najbardziej oczywistych znaków wskazujących na to, że dana firma faktycznie potrzebuje systemu CRM.
      </p>
      <p>
       <Link to="/piec-dobrych-praktyk-budowania-relacji-z-klientami"><strong>Zarządzanie relacjami z klientami w CRM </strong></Link> umożliwia handlowcom dostęp 
       do zawsze aktualnej bazy klientów. Mogą ich w łatwy sposób wyszukać po nazwie, 
       numerze NIP czy osobie albo skorzystać z&nbsp;dodatkowych kryteriów wyszukiwania – tak zwanych "atrybutów", które stanowią elementarne składniki systemu CRM.
      </p>
      <p>
      Można zatem powiedzieć, że dobry system CRM wspiera codzienną pracę handlowców i&nbsp;jest swego rodzaju bazą danych dotyczących klientów (ale nie tylko!). 
      Najważniejsze funkcje systemu CRM w&nbsp;tym względzie to gromadzenie wiedzy o&nbsp;kliencie i&nbsp;zarządzanie potencjalnymi klientami. To tak, jakby program CRM 
      składał się z poszczególnych skoroszytów, w&nbsp;których odpowiednio katalogują i&nbsp;porządkują się dane.
      </p>
      <h3 style={{
        paddingTop: 7,
          paddingBottom: 7,
        }}>Zarządzanie relacjami z klientami online – karta kontrahenta</h3>
      <p>
        Centralnym elementem każdego systemu klasy CRM w sprzedaży jest kartoteka lub karta klienta. To rozbudowany obiekt, 
        zbudowany z&nbsp;szeregu zakładek. Zgodnie ze strategią CRM, gromadzi on wiele najważniejszych informacji na temat 
        kontrahentów – ich nazwę, NIP, adres, osoby kontaktowe, opiekuna czy też wprowadzone przez pracowników notatki. 
        Jeżeli system zarządzania klientami jest zintegrowany z&nbsp;naszym serwerem poczty, to w tym miejscu będzie 
        się również archiwizować korespondencja z&nbsp;wybranym kontrahentem.
      </p>

               <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.karta]}
        alts={["Karta kontrahenta w systemie CRM"]}
      />

      <h3 style={{
        paddingTop: 7,
          paddingBottom: 7,
        }}>Najmniejsze składniki systemu CRM – atrybuty</h3>
      <p>
      Atrybuty to szereg informacji, którymi zgodnie z&nbsp;filozofią CRM możemy opisać swoich klientów, a&nbsp;które umożliwiają późniejszą ich 
      segmentację lub łatwiejsze wyszukiwanie żądanych grup kontrahentów. Systemy CRM pozwalają na ustalenie indywidualnych atrybutów, 
      dopasowanych do konkretnego modelu, w&nbsp;jakim działa nasza firma. Jako przykłady można wymienić status danego klienta - czy jest on 
      potencjalnym klientem, czy też już aktywnie z&nbsp;nim współpracujemy, na jakie produkty lub usługi jest potencjalny lub ilu pracowników zatrudnia.
      </p>
      <p>
        Są to indywidualne kryteria do targetowania klienta, które za pomocą systemu CRM możemy wykorzystać na przykład 
        do masowych akcji marketingowych – takich jak wysłanie mailingu do grupy wytypowanych odbiorców.
      </p>

      <h3 style={{
        paddingTop: 7,
          paddingBottom: 7,
        }}>Zadania i zdarzenia w oprogramowaniu CRM</h3>
      <p style={{
          paddingBottom: 20,
        }}>
        Zadania i&nbsp;zdarzenia są sercem każdej kartoteki kontrahenta w&nbsp;systemie CRM dla firm. To tutaj zapisana jest cała historia 
        współpracy z&nbsp;danym klientem. Zarejestrowane są w&nbsp;tym miejscu wszystkie podjęte względem niego działania – zrealizowane 
        spotkania, wykonane telefony czy przesłane lub otrzymane wiadomości. System CRM umożliwia, by w&nbsp;mgnieniu oka zorientować 
        się, na jakim etapie zarządzania potencjalnym klientem się znajdujemy i&nbsp;jak wyglądają bieżące z&nbsp;nim ustalenia.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Zadania i zdarzenia w karcie klienta w CRM"]}
      />
      <h3 style={{
        paddingTop: 27,
          paddingBottom: 7,
        }}>CRM w sprzedaży – dokumenty</h3>
      <p>
        W&nbsp;tej zakładce bazy danych CRM zbierane są wszystkie ważne dokumenty związane z&nbsp;klientem – mogą to być na 
        przykład przesłane faktury i&nbsp;zamówienia. Jeżeli posiadamy integrację systemu CRM z&nbsp;programem handlowo–magazynowym, 
        to w&nbsp;tym miejscu uzyskamy również dostęp do faktur klienta oraz informacji o&nbsp;jego rozliczeniach.
      </p>
      <h2 style={{
        paddingTop: 27,
          paddingBottom: 7,
        }}>Szanse sprzedaży – jak mobilny CRM w rzeczywistości poprawia obsługę klienta?</h2>
      <p style={{
          paddingBottom: 18,
        }}>
        Drugim kluczowym obszarem, w&nbsp;którym system CRM znajduje zastosowanie są szanse sprzedaży. To funkcjonalność 
        CRM, która umożliwia usystematyzowanie procesu sprzedaży i&nbsp;łatwe zarządzanie potencjalnymi klientami. Dzięki niej 
        można szybko zorientować się w&nbsp;ilości otwartych tematów sprzedażowych i&nbsp;określić, na jakim etapie znajdują 
        się poszczególne leady. Dobry system CRM umożliwia firmom, by wszystkie dane w&nbsp;zakresie danego tematu sprzedażowego 
        znajdowały się w&nbsp;jednym miejscu – od wykonanych kontaktów, przez oferty aż po zamówienia.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Szanse sprzedaży w CRM przedstawione w widoku Kanban"]}
      />
      <p style={{
          paddingTop: 14,
        }}>
        <Link to="/dashboard-managera"><strong>Analityczny system CRM</strong></Link>, dzięki rozbudowanym raportom, pozwala w tym miejscu również prognozować sprzedaż, 
        analizować konwersje lejka czy też przyczyny utraty szans sprzedaży. Główne zalety programu CRM nowej 
        generacji opierają się bowiem nie na samym fakcie gromadzenia danych, ale na sposobie ich wykorzystania 
        w&nbsp;zarządzaniu firmą. Najlepsze oprogramowania CRM umożliwiają dogłębne analizy, które z&nbsp;kolei pozwalają 
        na weryfikację tego, czy podejmowane przez firmę działania przynoszą korzyści. W&nbsp;ten właśnie sposób 
        system CRM wspiera ciągłe modyfikowanie i&nbsp;usprawnienie tych działań.
      </p>
                  <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.raporty]}
        alts={["Raporty sprzedażowe w oprogramowaniu CRM dla firm"]}
      />
      <h2 style={{
          paddingTop: 22,
          paddingBottom: 6,
        }}><Link to="/zalety-elastycznego-crm/">
             Zalety i&nbsp;możliwości systemu CRM w&nbsp;firmie
            </Link></h2>
      <p>
        Główną wartością płynącą z&nbsp;pracy na systemie CRM online jest to, w&nbsp;jaki sposób wspiera on proces sprzedaży i&nbsp;zarządzania 
        relacjami z&nbsp;klientami online. Funkcje i&nbsp;możliwości systemu CRM pomagają bowiem usprawnić te procesy, dzieląc je na 
        usystematyzowane działania i&nbsp;kierując działaniami handlowca. Ponadto, do kolejnych istotnych korzyści systemu 
        CRM należą analiza tych działań i&nbsp;ciągła ich optymalizacja.
      </p>
      <p>
      Chmurowy system CRM to również baza informacji o&nbsp;kliencie, która jest niezwykle cennym zasobem firmy. CRM ma znaczenie 
      dla Twojej firmy gromadząc dane o&nbsp;kontrahencie, ponieważ w&nbsp;ten sposób znacząco zmniejsza ryzyko jego utraty. Efektywny 
      CRM od ręki pozwala sprawdzić, kim jest klient, jak do tej pory przebiegała z&nbsp;nim współpraca, jakie działania się 
      sprawdzały i&nbsp;kiedy trzeba podjąć kolejne. Ważnym aspektem gromadzenia danych jest też zabezpieczenie przed odejściem 
      pracownika - dzięki temu nie dochodzi do sytuacji, w&nbsp;której gdzie pracownik opuszczając firmę zabiera wiedzę o&nbsp;kliencie ze sobą.
      </p>
      <p>
        Podsumowując, system informatyczny CRM spełnia w organizacji trzy następujące główne role:
      </p>
      <p>
        <ul>
          <li>
            <strong>komunikacyjna</strong>: usprawnia kontakt z klientem ale też przepływ informacji w firmie,
          </li>
          <li>
            <strong>operacyjna</strong>: automatyzuje procesy biznesowe wykonywane przez pracowników 
            sprzedaży, marketingu oraz obsługi klienta,
          </li>
          <li>
            <strong>analityczna</strong>: analizuje zgromadzone dane, zachowania klientów oraz podjęte działania i&nbsp;umożliwia 
            wykorzystywanie wyniki tych analiz do podejmowania strategicznych działań.
          </li>
        </ul>
      </p>

        <h2 style={{
        paddingTop: 27,
          paddingBottom: 7,
        }}>Co dalej? Jak wybrać odpowiedni system CRM?</h2>
      <p style={{
          paddingBottom: 18,
        }}>
        Wiesz już, czym jest rozwiązanie CRM i do czego służy firmie. Poznałeś także podstawowe rodzaje oprogramowania CRM od kuchni, 
        przedstawiliśmy Ci także najważniejsze zalety CRM i jego najważniejsze funkcje, które pomagają firmom się rozwijać. Czy to zatem 
        odpowiedni moment również i dla Ciebie, by zainwestować w CRM? Jeśli masz wątpliwości – <Link to="/kontakt"><strong>kliknij tutaj, by porozmawiać z naszym ekspertem</strong></Link>. 
        Oferujemy darmowe konsultacje, podczas których wspólnie przyjrzymy się Twojej firmie i zastanowimy się 
        czy i&nbsp;jak wprowadzenie systemu CRM może pomóc wznieść ją na wyższy poziom.
      </p>
      <p>
      Zastanawiasz się ile kosztuje system CRM? Chciałbyś sam zobaczyć jak działa system CRM dla małej firmy (lub ogólnie – firmy z sektora MŚP) 
      i co można osiągnąć dzięki tego rodzaju narzędziom? <a href="https://synergiuscrm.pl"><strong>Kliknij tutaj</strong></a> i&nbsp;przejdź na stronę systemu Synergius CRM, gdzie możesz zapoznać się 
      z naszym cennikiem, sprawdzić konkretne funkcje systemu CRM, a także stworzyć własną bezpłatną wersję demo CRM do testów. Jeśli 
      reprezentujesz większe przedsiębiorstwo lub masz bardziej niestandardowe potrzeby – <a href="https://aurabusiness.pl"><strong>kliknij tutaj</strong></a> i zapoznaj się z dedykowanym rozwiązaniem Aura Business.
      </p>

      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Sprawdź funkcje i możliwości systemu CRM w Twojej firmie!</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas i uzyskaj dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i&nbsp;z&nbsp;sukcesem wdrożyć system CRM
            </Link>
          </div>
          </div>
    </section>

    <p style={{
          paddingTop: 30,
        }}>Mobilne systemy CRM – dowiedz się więcej:</p>
      <p>
        <ul>
          <li>
            <Link to="/modul-serwisowy/">
              Dlaczego warto łączyć moduł serwisowy z&nbsp;systemem CRM?
            </Link>
          </li>
          <li>
            <Link to="/analiza-przedwdrozeniowa/">
              Czym jest analiza przedwdrożeniowa? Dlaczego to must-have wdrożenia systemu dedykowanego?
            </Link>
          </li>
          <li>
            <Link to="/wsparcie-sprzedazy/">
              Wsparcie sprzedaży – jak CRM może pomóc menedżerom sprzedaży?
            </Link>
          </li>
           <li>
            <Link to="/wdrozenie-crm/">
             Porażki wdrożeń CRM - dlaczego się nie udaje?
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Specrm;
